<template>
  <b-container class="tree-types">
    <b-row>
      <b-col>
        <h1>Tree Types</h1>
      </b-col>
    </b-row>

    <TreeTypeListComponent/>
  </b-container>
</template>

<script>
import TreeTypeListComponent from "../components/TreeType/TreeTypeListComponent";

export default {
  name: 'TreeTypes',

  components: {TreeTypeListComponent}
}
</script>

<style scoped>

</style>
