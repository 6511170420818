<template>
  <div class="tree-type-list-component">
    <b-row v-if="search">
      <b-col class="my-1" lg="6">
        <b-btn size="sm" variant="primary" @click="$router.push('/tree-types/new')">
          <b-icon-plus></b-icon-plus>
          Add new
        </b-btn>
      </b-col>
      <b-col class="my-1" lg="6">
        <b-form-group
          class="mb-0"
          label="Filter"
          label-align-sm="right"
          label-cols-sm="3"
          label-for="filter-input"
          label-size="sm"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              placeholder="Type to Search"
              type="search"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="this.filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      :current-page="currentPage"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn"
      :items="items"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      responsive
      show-empty
      small
      stacked="md"
      @filtered="onFiltered"
    >
      <template #cell(name)="row">
        {{ row.value }}
      </template>

      <template #cell(actions)="row">
        <b-button class="mr-1" size="sm" variant="primary" @click="$router.push('/tree-types/' + row.item.id)">
          <b-icon-pencil-square></b-icon-pencil-square>
          Edit
        </b-button>
        <b-button class="mr-1" size="sm" variant="secondary" @click="deleteTreeType(row.item.id)">
          <b-icon-trash></b-icon-trash>
          Delete
        </b-button>
      </template>

      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>

    <b-row v-if="pagination">
      <b-col class="my-1" md="6" sm="5">
        <b-form-group
          class="mb-0"
          label="Per page"
          label-align-sm="left"
          label-cols-lg="3"
          label-cols-md="4"
          label-cols-sm="6"
          label-for="per-page-select"
          label-size="sm"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col class="my-1 d-flex justify-content-end" md="6" sm="7">
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          class="my-0"
          size="sm"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TreeType from '../../api/TreeType'
import Swal from 'sweetalert2'

export default {
  name: 'TreeTypeListComponent',

  data() {
    return {
      items: [],
      search: true,
      pagination: true,
      fields: [
        {key: 'id', label: 'ID', sortable: true, sortDirection: 'desc'},
        {key: 'reference', label: 'Reference', sortable: true, sortDirection: 'asc'},
        {key: 'name', label: 'Name', sortable: true, sortDirection: 'asc'},
        {key: 'actions', label: 'Actions', thStyle: {width: '200px'}}
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 20, 50, {value: 100, text: "Show a lot"}],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: []
    }
  },
  created() {
    this.getTreeTypes()
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return {text: f.label, value: f.key}
        })
    }
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },

  methods: {
    getTreeTypes() {
      TreeType.getTreeTypes().then((response) => {
        this.items = response.data
        this.totalRows = this.items.length
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    deleteTreeType(id) {
      if (id) {
        Swal.fire({
          title: 'Are you sure?',
          text: "You are going to delete a tree type",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            TreeType.delTreeTypes(id).then(() => {
              this.getTreeTypes()
              Swal.fire(
                'Deleted!',
                'The tree type has been deleted',
                'success'
              )
            })

          }
        })

      }
    }
  }
}
</script>
<style scoped>

</style>
