import { render, staticRenderFns } from "./TreeTypes.vue?vue&type=template&id=63dcd8eb&scoped=true"
import script from "./TreeTypes.vue?vue&type=script&lang=js"
export * from "./TreeTypes.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63dcd8eb",
  null
  
)

export default component.exports