import Ajax from './Ajax.js'

const TreeType = {
  getTreeTypes: (id = null) => {
    let uri = id !== null
      ? '/tree-types' + '/' + id
      : '/tree-types'
    return Ajax.get(uri)
  },
  getTreeTypesByRef: (ref) => {
    let uri = `/tree-types?reference_eq=${ref}`;
    return Ajax.get(uri)
  },

  postTreeTypes: (params) => {
    return Ajax.post('/tree-types', params)
  },

  putTreeTypes: (params) => {
    return Ajax.put('/tree-types/' + params.id, params)
  },

  delTreeTypes: (id) => {
    return Ajax.delete('/tree-types/' + id)
  }
}

export default TreeType
